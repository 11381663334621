import axios from 'axios'
import Utils, {InputFile} from "./Utils";
import {Group} from "../system/app/company/Groups";
import {PaymentTier} from "../system/app/management/PricingData";

export class Request {
    //static BASE_URL = "https://dev.coronatest.ug/"

    static BASE_URL = "https://api.coronatest.ug/"

    //static BASE_URL = "https://case.api.wilsofts.com/"
    //static BASE_URL = "https://coronaapi.tennaandpharmalab.com/"

    //static BASE_URL = "https://coronaapi.medsafehospital.com/"
    //static BASE_URL = "https://meapi.wilsofts.com/"

    //static BASE_URL = "http://127.0.0.1:8014/"

    static COMPANY: "MEDIPAL" | "PHARMA" | "MEDSAFE" | "MIDDLE EAST" = "MEDIPAL"
    static CURRENT: boolean = true
    static TRUCK_DRIVERS: boolean = true

    /*dashboard results*/
    static dashboard(params: { min_date: string, max_date: string }) {
        return axios({method: 'get', url: 'dashboard', params: params});
    }

    static backup_database() {
        return axios({method: 'post', url: 'export/backup'});
    }

    /******************************** USER REQUESTS ************************/
    static save_test_center(params: {
        center_id: number, center_name: string, center_logo: InputFile, center_contact: string, center_email: string,
        box_number: string, location: string, test_prefix: string, center_type: 'testing' | 'booking', center_roles: string,
    }) {
        return axios({method: 'post', url: 'users/centers/save', data: Utils.post_params(params)});
    }

    static get_test_center() {
        return axios({method: 'get', url: 'users/centers/get'});
    }

    static save_user(data: {
        first_name: string, last_name: string, user_name: string, login_password: string, user_roles: string,
        user_id: number, center_id: number, email_address: string
    }) {
        return axios({method: 'post', url: 'users/account/create', data: Utils.post_params(data)});
    }

    static verify_user_account(data: { login_token: string, new_password: string }) {
        return axios({method: 'post', url: 'users/account/verify', data: Utils.post_params(data)});
    }

    static login_user(data: { credential: string, login_password: string, fcm_token: string }) {
        return axios({method: 'post', url: 'users/account/login', data: Utils.post_params(data)});
    }

    static update_fcm_token(data: { fcm_token: string }) {
        return axios({method: 'post', url: 'users/fcm_token', data: Utils.post_params(data)});
    }

    static get_users(params: { center_id: number }) {
        return axios({method: 'get', url: 'users/get', params: params});
    }

    static get_day_users(params: { date: string, user_id: number, center_id: number }) {
        return axios({method: 'get', url: 'users/daily', params: params});
    }

    /**************************** END OF USER REQUESTS ****************************/

    /*************************** DIAGNOSIS DATA **********************/
    static initialise_diagnosis(params: { client_id: number }) {
        return axios({method: 'get', url: 'diagnosis/initialise', params: params});
    }

    static save_test(params: {
        client_id: number, test_type: string, amount_paid: number, payment_method: string, discount_reason: string,
        group_id: number, discount: number, payments: string, triage: string, travel_time: string, purpose: string,
        other_payments: string, other_payment: number, proceed: 1 | 0, virology: 1 | 0,
        tested_positive: 'Yes' | 'No', vaccinated: 'Yes' | 'No', vaccine_doses: number, last_dose: string, eac_pass_id: string,
        vaccine: '' | 'AstraZeneca' | 'Pfizer' | 'Moderna' | 'Sinopham' | 'Johnson & Johnson' | 'Sinovac',
    }) {
        return axios({method: 'post', url: 'diagnosis/save', data: Utils.post_params(params)});
    }

    static prove_diagnosis(params: { diagnosis_id: number, diagnosis_no: string, tube: string, client: string, }) {
        return axios({method: 'post', url: 'diagnosis/proof', data: Utils.post_params(params)});
    }

    static save_machine(params: { machine_id: number, machine_name: string, machine_no: string, kit_name: string, genes: string }) {
        return axios({method: 'post', url: 'diagnosis/machine/save', data: Utils.post_params(params)})
    }

    static save_machine_interpretations(params: { machine_id: number, interpretations: string }) {
        return axios({method: 'post', url: 'diagnosis/machine/interpretations', data: Utils.post_params(params)})
    }

    static get_machines() {
        return axios({method: 'get', url: 'diagnosis/machine/get'})
    }

    static get_results_tests(params: { min_date: string, max_date: string, status: string, center_id: number }) {
        return axios({method: 'get', url: 'diagnosis/tests/results', params: params});
    }

    static load_csv_results(params: { min_date: string, max_date: string, status: string, center_id: number }) {
        return axios({method: 'get', url: 'diagnosis/results/data', params: params});
    }

    static download_csv_file(params: { ids: string, min_date: string, max_date: string, status: string, center_id: number }) {
        return axios({method: 'get', url: 'diagnosis/results/csv', params: params});
    }

    static upload_csv_results(params: { ids: string }) {
        return axios({method: 'post', url: 'diagnosis/results/uploaded', data: Utils.post_params(params)});
    }

    static synchronise_csv_file(params: { ids: string, duplicate?: number, no_gene?: 1 }) {
        return axios({method: 'post', url: 'diagnosis/results/sync', data: Utils.post_params(params)});
    }

    static get_tests_reports(params: {
        min_date: string, max_date: string, status: string, upload_status: number, center_id: number,
        rows: string, type: 'table' | 'pdf' | 'csv'
    }) {
        return axios({method: 'get', url: 'diagnosis/tests/reports', params: params});
    }

    static get_lab_igg_igm(params: { min_date: string, max_date: string, center_id: number }) {
        return axios({method: 'get', url: 'diagnosis/lab/igg_igm', params: params});
    }

    /************************** END OF DIAGNOSIS *********************/


    /*************************** PAYMENT DATA **********************/
    static save_pricing(data: PaymentTier) {
        return axios({method: 'post', url: 'payment/pricing/save', data: Utils.post_params(data)});
    }

    static get_pricing_tier() {
        return axios({method: 'get', url: 'payment/pricing/get', params: {}});
    }

    static check_booking_reference(params: { booking_ref: string }) {
        return axios({method: 'get', url: 'payment/booking/check', params: params});
    }

    static get_payment_info(params: { payment_info_id: number, diagnosis_id: number }) {
        return axios({method: 'get', url: 'payment/update/info', params: params});
    }

    static update_payment(params: {
        payment_info_id: number, diagnosis: string, payments: string, user_id: number
    }) {
        return axios({method: 'post', url: 'payment/update/save', data: Utils.post_params(params)});
    }

    static get_payments(params: { min_date: string, max_date: string, cashier_name: number, center_id: number }) {
        return axios({method: 'get', url: 'payment/report/list', params: params});
    }

    static get_payments_pdf(params: { min_date: string, max_date: string, cashier_name: number, center_id: number }) {
        return axios({method: 'get', url: 'payment/report/pdf', params: params});
    }

    static print_receipt(params: { diagnosis_id: number, payment_info_id: number }) {
        const _params = {...params, type: ["MEDIPAL", "MEDSAFE", "MIDDLE EAST"].includes(Request.COMPANY) ? 'pdf' : 'list'}
        return axios({method: 'post', url: 'payment/receipt', data: Utils.post_params(_params)});
    }

    static initiate_payment(params: { payment_amount: number, client_id: number }) {
        return axios({method: 'post', url: 'payment/initiate', data: Utils.post_params(params)});
    }

    static get_pending_payments(params: { center_id: number }) {
        return axios({method: 'get', url: 'payment/pending/get', params: params});
    }

    static save_pending_payments(params: { payments: string, diagnosis_ids: string }) {
        return axios({method: 'post', url: 'payment/pending/pay', data: Utils.post_params(params)});
    }

    /************************** END OF PAYMENT *********************/

    /***************************** GROUPS ****************************/
    static save_group(data: Group) {
        return axios({method: 'post', url: 'groups/save', data: Utils.post_params(data)});
    }

    static get_groups(params: { name: string }) {
        return axios({method: 'get', url: 'groups/get', params: params});
    }

    static get_groups_members(params: { group_id: number }) {
        return axios({method: 'get', url: 'groups/members', params: params});
    }

    static get_group_report(params: {
        min_date: string, max_date: string, group_id: number, client_name: string, payment_status: string, center_id: number
    }) {
        return axios({method: 'get', url: 'groups/invoices/report', params: params});
    }

    static get_group_pending_invoices(params: { group_id: number }) {
        return axios({method: 'get', url: 'groups/invoices/pending', params: params});
    }

    static print_group_invoice(data: {
        ids: string, group_id: number, invoice_no: string, tin_no: string, address_person: string
    }) {
        return axios({method: 'post', url: 'groups/invoices/print', data: Utils.post_params(data)});
    }

    static update_to_invoiced(data: { ids: string, group_id: number }) {
        return axios({method: 'post', url: 'groups/invoices/update', data: Utils.post_params(data)});
    }

    static save_group_payment(data: {
        payment_id: number, group_id: number, amount_paid: number, payment_method: string,
        date_paid: string, reference_no: string, other_details: string, ids: string
    }) {
        return axios({method: 'post', url: 'groups/invoices/payment', data: Utils.post_params(data)});
    }

    static get_group_payment(params: { group_id: number }) {
        return axios({method: 'get', url: 'groups/invoices/payments', params: params});
    }

    /**************************************** END OF GROUPS *********************************/

    /*****************************************TESTS DATA***************************************/
    static delete_test(params: { diagnosis_id: number, cancel_reason: string, status: string }) {
        return axios({method: 'post', url: 'tests/delete', data: Utils.post_params(params)});
    }

    static receive_samples(params: { samples: string }) {
        return axios({method: 'post', url: 'diagnosis/lab/receive/pcr', data: Utils.post_params(params)});
    }

    static get_received_samples() {
        return axios({method: 'get', url: 'diagnosis/lab/load/get'});
    }

    static print_loaded_samples() {
        return axios({method: 'get', url: 'diagnosis/lab/load/print'});
    }

    static load_samples(params: { samples: string, machine_id: number, has_targets: 1 | 0 }) {
        return axios({method: 'post', url: 'diagnosis/lab/load/pcr', data: Utils.post_params(params)});
    }

    static receive_igg_igm_tests(params: { diagnosis_nos: string }) {
        return axios({method: 'post', url: 'diagnosis/lab/receive/igg_igm', data: Utils.post_params(params)});
    }

    static update_tests(params: { results: 'Inconclusive' | 'Positive' | 'Invalid' | 'Negative', diagnosis_no: string, genes: string }) {
        return axios({method: 'post', url: 'diagnosis/lab/update/pcr', data: Utils.post_params(params)});
    }

    static update_igg_igm_tests(params: { igg_result: number, igm_result: number, diagnosis_id: number }) {
        return axios({method: 'post', url: 'diagnosis/lab/update/igg_igm', data: Utils.post_params(params)});
    }

    static upload_csv_excel_results(data: { results_file: File, machine_id: number }) {
        const form = new FormData();
        form.append("machine_id", data.machine_id.toString())
        form.append("results_file", data.results_file)
        return axios({method: 'post', url: 'diagnosis/lab/update/csv/upload', data: form});
    }

    static save_uploaded_csv_results(params: { results: string }) {
        return axios({method: 'post', url: 'diagnosis/lab/update/csv/save', data: Utils.post_params(params)});
    }

    static verify_all_pcr_test(params: { ids: string }) {
        return axios({method: 'post', url: 'diagnosis/lab/verify/pcr', data: Utils.post_params(params)});
    }

    static verify_igg_igm_results(params: { ids: string }) {
        return axios({method: 'post', url: 'diagnosis/lab/verify/igg_igm', data: Utils.post_params(params)});
    }

    static print_igg_igm_results(params: { diagnosis_id: number }) {
        return axios({method: 'get', url: 'diagnosis/print/igg_igm', params: params});
    }

    static get_test_data(params: { diagnosis_id: number }) {
        return axios({method: 'get', url: 'tests/info', params: params});
    }

    static get_virology_data(params: { diagnosis_id: number }) {
        return axios({method: 'get', url: 'tests/virology/info', params: params});
    }

    static print_virology_data(params: { diagnosis_id: number }) {
        return axios({method: 'get', url: 'tests/virology/print', params: params});
    }

    static update_virology_data(params: {
        igg: string, igm: string, analysed_by: number, diagnosis_id: number, verified_by: number
    }) {
        return axios({method: 'post', url: 'tests/virology/update', data: Utils.post_params(params)});
    }

    /************************************** END OF TESTS DATA *************************************/

    /********************* CLIENT MANAGEMENT ******************************/
    static add_client_discount(data: { client_id: number, discount: number, discount_reason: string }) {
        return axios({method: 'post', url: 'client/discount', data: Utils.post_params(data)});
    }

    static update_account(clientInfo: {
        client_id: number, first_name: string, last_name: string, other_name: string, client_gender: string, date_of_birth: string,
        client_age: string | number, passport_photo: InputFile, mobile_contact: string, email_address: string, passport_no: string,
        group_id: number, nationality: string, village: string, sub_county: string, parish: string, district: string,
        next_of_kin: string, next_of_kin_contact: string
    }) {
        return axios({method: 'post', url: 'client/info/update/account', data: Utils.post_params(clientInfo)});
    }

    static search_clients(params: { search: string, center_id: number }) {
        return axios({method: 'get', url: 'client/search', params: params});
    }

    static send_user_certificate(data: {
        certificate: File, email_address: string, client_name: string, main_body: string, diagnosis_id: number
    }) {
        return axios({method: 'post', url: 'certificate/send', data: Utils.post_params(data)});
    }

    static upload_certificates(data: { certificates: Array<File>, email: 1 | 0, email_address: string }) {
        const form = new FormData();
        form.append("email", data.email.toString())
        form.append("email_address", data.email_address.toString())
        data.certificates.forEach((certificate) => form.append("certificates[]", certificate))
        return axios({method: 'post', url: 'certificate/upload', data: form});
    }

    /********************* END OF CLIENT MANAGEMENT ******************************/
}
