import {Button, Confirm, Modal, Table} from "semantic-ui-react";
import Utils, {Loading, my_initial_confirm, MyConfirmAction} from "../../../../../utils/Utils";
import React from "react";
import {Request} from "../../../../../utils/Request";
import {ReportTest, sample_status} from "../PCRTests";
import {SystemUser} from "../../../../AppBar";
import axios from "axios";
import {ToastsStore} from "react-toasts";
import moment from "moment";

interface Log {
    log_details: string
    log_name: sample_status
    log_time: string
    user_name: string
}

interface Test {
    diagnosis_id: number
    diagnosis_no: string
    client_name: string
    results: "Positive" | 'Negative' | 'N/A' | 'Invalid' | 'Inconclusive'
    logs: Log[]
    triage: { date: '', signs: string[], state: string[] }
}

export default function DiagnosisDetails(params: {
    tests: ReportTest[], setTests: (tests: ReportTest[]) => void, diagnosis_id: number, close: () => void, systemUser: SystemUser
}) {
    const [confirm, setConfirm] = React.useState<MyConfirmAction>(my_initial_confirm)
    const [loader, setLoader] = React.useState({show: false, message: ""})
    const [test, setTest] = React.useState<Test>({
        diagnosis_id: 0, diagnosis_no: "", results: "N/A", triage: {date: "", signs: [], state: []}, client_name: "", logs: []
    })

    const get_test_data = (diagnosis_id: number) => {
        setLoader({message: "Loading test data, please wait", show: true})
        Request.get_test_data({diagnosis_id: diagnosis_id})
            .then((response) => {
                setLoader({message: "", show: false})
                if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                    setTest(response.data.data)
                } else {
                    params.close()
                }
            })
            .catch(() => {
                setLoader({message: "", show: false})
                params.close()
            })
    }

    const re_upload_results = () => {
        setConfirm({
            ...confirm,
            open: true, content: 'Are you sure you want to re-dispatch these results',
            confirmButton: "Proceed", cancelButton: "Cancel",
            onCancel: () => setConfirm({...confirm, open: false}),
            onConfirm: () => {
                setConfirm({...confirm, open: false})
                axios.defaults.timeout = 1000 * 60 * 5
                setLoader({message: "Dispatching results to RDS, please wait", show: true})

                Request.synchronise_csv_file({
                    ids: JSON.stringify([params.diagnosis_id]), no_gene: 1,
                    duplicate: test.logs.filter((log) => log.log_name === "uploaded").length
                })
                    .then((response) => {
                        setLoader({message: "", show: false})
                        if (response.data.hasOwnProperty("code")) {
                            if (response.data.code === 1) {
                                ToastsStore.success("Successfully synchronised results to RDS")
                                //setResponses(response.data.responses)
                            } else {
                                ToastsStore.error("Could not synchronise results to RDS, please retry")
                            }
                        }
                        axios.defaults.timeout = 1000 * 60
                    })
                    .catch(() => {
                        setLoader({message: "", show: false})
                        ToastsStore.error("Could not synchronise results to RDS, please retry")
                        axios.defaults.timeout = 1000 * 60
                    })
            }
        })
    }

    React.useEffect(() => {
        if (params.diagnosis_id > 0) {
            get_test_data(params.diagnosis_id)
        }
    }, [params.diagnosis_id])

    return (
        <>
            <Loading show={loader.show} text={loader.message} hide={() => setLoader({...loader, show: false})}/>

            <Confirm open={confirm.open} onCancel={confirm.onCancel} onConfirm={confirm.onConfirm} cancelButton={confirm.cancelButton}
                     confirmButton={confirm.confirmButton} size='mini' content={confirm.content} centered={false}/>

            <Modal size='small' open={params.diagnosis_id > 0} centered={false} onClose={params.close}>
                <div className="verify_container">
                    <div className="diagnosis_no">{test.diagnosis_no}</div>

                    <div className="verify_data">
                        <div className="label">Client Name</div>
                        <div className="label_info">{test.client_name}</div>

                        <div className="label">Diagnosis Results</div>
                        <div className="label_info">{test.results}</div>

                        {
                            test.triage.date !== "" &&
                            <>
                                <div className="label">Symptoms Date</div>
                                <div className="label_info">{moment(test.triage.date).format("lll")}</div>
                            </>
                        }

                        {
                            test.triage.signs.length > 0 &&
                            <>
                                <div className="label mt-3">Clinical Results</div>
                            </>
                        }

                        {
                            test.triage.state.length > 0 &&
                            <>
                                <div className="label mt-3">Other Conditions</div>
                            </>
                        }

                        <div className="label mt-3">Diagnosis Logs</div>
                        <div className="table_container">
                            <Table celled striped compact size='small' inverted color='grey' selectable unstackable={true}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{width: '50px'}} textAlign="center">No.</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '90px'}}>Log Type</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '140px'}}>Log Date</Table.HeaderCell>
                                        <Table.HeaderCell style={{width: '180px'}}>User Name</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        test.logs.map((log, index) =>
                                            <Table.Row key={index}>
                                                <Table.Cell style={{width: '50px'}} textAlign="center">{Utils.row_number(index)}</Table.Cell>
                                                <Table.Cell style={{width: '90px'}}>
                                                    {log.log_name.charAt(0).toUpperCase() + log.log_name.slice(1)}
                                                </Table.Cell>
                                                <Table.Cell style={{width: '140px'}}>{Utils.localise_date(log.log_time)}</Table.Cell>
                                                <Table.Cell style={{width: '180px'}}>{log.user_name}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }
                                </Table.Body>
                            </Table>
                        </div>
                        {

                        }
                    </div>

                    <div className="row verify_button m-0">
                        <div className="col-6 offset-3 pr-1 pl-0">
                            <Button positive icon="save" labelPosition="left" size="mini" fluid
                                    disabled={
                                        !params.systemUser.roles.includes("rds_sync") ||
                                        test.logs.filter((log) => log.log_name === "uploaded").length === 0
                                    }
                                    content="Re-Upload" onClick={re_upload_results}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
